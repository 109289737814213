import React, {Fragment} from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { listingFieldEnum, listingTypeEnum } from '../../config/configListingEnums';
import renderMarkdown from '../../containers/PageBuilder/markdownProcessor';
import {useCSSVariable} from "../../styles/useCSSVariable";

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const marketplaceColor = useCSSVariable('--marketplaceColor', '#6c3a87');
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const authorIconVariants = author.profileImage?.attributes?.variants;
  const authorIconVariant = authorIconVariants?.['square-small'] || authorIconVariants?.['scaled-small'];
  const authorIconURL = authorIconVariant?.url;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const listingType = publicData?.listingType;
  const priceRange = publicData?.[listingFieldEnum.PRICE_RANGE];
  const priceRangeLabel = config.listing.listingFields.find(field => field.key === listingFieldEnum.PRICE_RANGE)?.enumOptions?.find(option => option.option === priceRange)?.label;
  const dealsTitle = publicData?.[listingFieldEnum.DEALS_TITLE];
  const today = new Date().getDay(); // 0 is Sunday, 6 is Saturday
  const openingHoursFieldIDs = [listingFieldEnum.APPOINTMENT_TIME_SUNDAY, listingFieldEnum.APPOINTMENT_TIME_MONDAY, listingFieldEnum.APPOINTMENT_TIME_TUESDAY, listingFieldEnum.APPOINTMENT_TIME_WEDNESDAY, listingFieldEnum.APPOINTMENT_TIME_THURSDAY, listingFieldEnum.APPOINTMENT_TIME_FRIDAY, listingFieldEnum.APPOINTMENT_TIME_SATURDAY];
  const openingHoursToday = publicData?.[openingHoursFieldIDs[today]];
  const tagline = publicData?.[listingFieldEnum.TAGLINE];
  const phoneNumber = publicData?.[listingFieldEnum.PHONE_NUMBER];
  const address = publicData?.location?.address;
  const firstCategory = publicData?.[listingFieldEnum.CATEGORY]?.[0];
  const first3Categories = publicData?.[listingFieldEnum.CATEGORY]?.slice(0, 3);
  const firstCategoryLabel = config.listing.listingFields.find(field => field.key === listingFieldEnum.CATEGORY)?.enumOptions?.find(option => option.option === firstCategory)?.label;
  const first3CategoriesLabels = first3Categories?.map(category => config.listing.listingFields.find(field => field.key === listingFieldEnum.CATEGORY)?.enumOptions?.find(option => option.option === category)?.label);
  const partnerListingTitle = publicData?.partnerListingTitle;
  const nonMemberPriceDollars = publicData?.[listingFieldEnum.NON_MEMBER_PRICE_DOLLARS];
  const nonMemberPriceMoney = nonMemberPriceDollars && new Money(nonMemberPriceDollars * 100, config.currency);
  const dateTime = publicData?.[listingFieldEnum.START_DATE_TIME];
  const deals = publicData?.[listingFieldEnum.DEALS];
  const locationSummary = publicData?.[listingFieldEnum.LOCATION_SUMMARY];

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
        style={{ position: 'relative' }}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <div style={{ position: 'absolute', top: '0', left: '0', padding: '5px', display: 'flex' }}>
          {listingType === listingTypeEnum.PROMO && firstCategoryLabel && (
            <div style={{
              border: '1px solid #fff',
              borderRadius: '5px',
              padding: '5px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
              {firstCategoryLabel}
            </div>
          )}
          {priceRangeLabel &&
            <div style={{
              border: '1px solid #fff',
              borderRadius: '5px',
              padding: '5px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
              {priceRangeLabel}
            </div>}
          {dealsTitle &&
            <div style={{
              border: '1px solid #fff',
              borderRadius: '5px',
              padding: '5px',
              backgroundColor: `${marketplaceColor}80`,
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
              {dealsTitle}
            </div>}
          {!dealsTitle && openingHoursToday &&
            <div style={{
              border: '1px solid #fff',
              borderRadius: '5px',
              padding: '5px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
              {openingHoursToday}
            </div>}
        </div>
        {authorIconURL && (
          <img src={authorIconURL} alt={authorName} style={{ position: 'absolute', bottom: '-10px', left: '0', height: '48px', borderRadius: '50%', backgroundColor: '#fff' }} />
        )}
      </AspectRatioWrapper>
      <div className={css.info}>
        <div style={{ display: 'flex' }}>
          {nonMemberPriceMoney && <>
            <div style={{ textDecoration: 'line-through', color: '#999', fontSize: '12px', fontWeight: 'bold' }}>
              <PriceMaybe price={nonMemberPriceMoney} publicData={publicData} config={config} intl={intl} />
            </div>
            &nbsp;&nbsp;
          </>}
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {tagline && (
            <div className={css.authorInfo}>
              {tagline}
            </div>
          )}
          {listingType === listingTypeEnum.EVENT && address && (
            <div className={css.authorInfo}>
              <img src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/location-pin.svg"
                   style={{ height: '1em', width: '1em', marginRight: '5px' }} />
              {address.split(',')[0]}
            </div>
          )}
          {listingType === listingTypeEnum.EVENT && dateTime && (
            <div className={css.authorInfo}>
              <img src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/calendar-days.svg"
                    style={{ height: '1em', width: '1em', marginRight: '5px' }} />
              {dateTime}
            </div>
          )}
          {listingType !== listingTypeEnum.PARTNER && partnerListingTitle && (
            <div className={css.authorInfo}>
              {partnerListingTitle}
            </div>
          )}
          <div className={css.authorInfo}>
            {phoneNumber && (
              <>
                <img src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/phone.svg"
                     style={{ height: '1em', width: '1em', marginRight: '5px' }} />
                {phoneNumber}
                &nbsp;&nbsp;
              </>
            )}
            {listingType === listingTypeEnum.PARTNER && (locationSummary || address) && (
              <>
                <img src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/location-pin.svg"
                     style={{ height: '1em', width: '1em', marginRight: '5px' }} />
                {locationSummary || address.split(',')[0]}
              </>
            )}
          </div>
          {listingType === listingTypeEnum.PARTNER && first3Categories?.length > 0 && (
            <div className={css.title}>
              {first3Categories.map((category, index) => (
                <Fragment key={category}>
                  {index > 0 && <>&nbsp;&nbsp;</>}
                  <img src={`/static/icons/fontawesome-free-6.5.1-web/svgs/solid/${category}.svg`}
                       style={{ height: '1em', width: '1em', marginRight: '5px' }} />
                  {first3CategoriesLabels[index]}
                </Fragment>
              ))}
            </div>
          )}
          {deals && false && (
            <div className={css.authorInfo}>
              <img src="/static/icons/fontawesome-free-6.5.1-web/svgs/solid/comment-dollar.svg"
                   style={{ height: '1em', width: '1em', marginRight: '5px' }} />
              {renderMarkdown(deals.split('\n')[0], {p: React.Fragment})}
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
