import React, { useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl';
import { string, shape, number, object } from 'prop-types';
import polyline from '@mapbox/polyline';

import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { circlePolyline } from '../../util/maps';

const formatColor = color => {
  return color.replace(/^#/, '');
};

const fuzzyCircleOverlay = (center, mapsConfig) => {
  const strokeWeight = 1;
  const strokeColor = mapsConfig.fuzzy.circleColor;
  const strokeOpacity = 0.5;
  const fillColor = mapsConfig.fuzzy.circleColor;
  const fillOpacity = 0.2;

  const path = circlePolyline(center, mapsConfig.fuzzy.offset);
  const styles = `-${strokeWeight}+${formatColor(strokeColor)}-${strokeOpacity}+${formatColor(
    fillColor
  )}-${fillOpacity}`;
  return `path${styles}(${encodeURIComponent(polyline.encode(path))})`;
};



const StaticMapboxMap = props => {
  const { address, center, zoom, mapsConfig, dimensions, mapIcon } = props;
  const { width, height } = dimensions;

  const libLoaded = typeof window !== 'undefined' && window.mapboxgl;
  if (!libLoaded) {
    return null;
  }

  const map = useRef(null);
  const mapContainerRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (map.current) {
      return;
    }
    mapboxgl.accessToken = mapsConfig.mapboxAccessToken;
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [center.lng, center.lat],
      zoom: zoom
    });
  });

  useEffect(() => {
    if (map.current && !markerRef.current) {
      markerRef.current = new mapboxgl.Marker(mapIcon).setLngLat([center.lng, center.lat]).addTo(map.current);
    }
  });

  return <div ref={mapContainerRef} alt={address}  style={{width: "100%", height: "100%"}}></div>
};

StaticMapboxMap.defaultProps = {
  address: '',
  center: null,
};

StaticMapboxMap.propTypes = {
  address: string,
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number.isRequired,
  mapsConfig: object.isRequired,

  // from withDimensions
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default lazyLoadWithDimensions(StaticMapboxMap, { maxWidth: '640px' });
